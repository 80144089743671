import React from 'react';

import Popup from '../../components/Popup/Popup';
import { useState } from 'react';
import { SubHeading } from '../../components';
import {images} from '../../constants';
import './Header.css';

const Header = () => {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className="app__header app__wrapper section__padding" id="home">
      <div className="app__wrapper_info">
        <SubHeading title="Quality Food at The Best Pricing!"/>
        <h1 className="app__header-h1">Sit, Sip, and Eat at La Bawarchi!</h1>
        <p className="p__opensans" style={{margin: '2rem 0'}}>On those tired afternoons, do you feel to sit and charge up your energy after long hours of tasks? We think of you and feed you with the most delicious meals for your round-the-clock cravings! La Bawarchi ends your search for the best cafe in Mohali with a snooker pool setup. Explore the breakfast menu and end up your day with scrumptious, gut-filling thali options.<br/><br/>La Bawarchi has curated a relaxing vibe for you where you can order your favorite meals and combos while refreshing your mind with a quick Snooker game. When you enter La Bawarchi, you start vibing in a soothing environment where the mouth-watering meal fragrances mix up with a perfect gaming area.</p>
        <SubHeading title="Fun Point - Skill Up Your Snooker Game @ just ₹50!"/>
        <button type="button" className="custom__button" onClick={() => setButtonPopup(true)}>Explore Menu</button>
      </div>

      <div className="app__pop">
        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
            <img src={images.cafemenu} alt="" />
        </Popup>
      </div> 
      
      <div className="app__wrapper_img">
        <img src={images.welcome} alt="header img"/>
      </div>
    </div>
  )
}

export default Header;
