import React from 'react';

import './FooterOverlay.css';

const FooterOverlay = () => (
  <div className="app__footerOverlay">
    <div className="app__footerOverlay-black">
      <h3>Catering Deliciousness Doesn’t Count Numbers!</h3>
      <p>Planning an official event or a private party? La Bawarchi will serve delicious food to your guests making your party a hit!<br/> 
        We offer catering services for numerous events like corporate meetings, official gatherings, birthday parties, and house parties.<br/>
        Let the food frenzy begin!
        </p>
      
      <h2>Call Us Now: (+91) 9875-929303</h2>
    </div>

    <div className="app__footerOverlay-img app__bg" />
  </div>
);

export default FooterOverlay;