import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      <img src={images.G} alt="g letter" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">La Bawarchi greets you with heavenly coffee fragrances that you truly need after spending your day planning some crucial business goals. When your fantastic mind plans meet our enthusiastic coffee clans, that's when you hit the right road! Come, join our food-tastic community with mind refreshing mocktails with soul-soothing snacks!<br/><br/>Taking off your dull breaks from your days, our team is always there to serve your soul with delicious meals and blissful drinks. Whether you are looking for a quick bite to grab on your way or a leisure meal with your friends, La Bawarchi is the ideal cafe on Airport Road, Mohali. Facing a hot afternoon? Order from the fruit juice and lemonade series! Enjoying an evening with your friends? Order the Mojito and Cold Coffee series!</p>
        <button type="button" className="custom__button">Know More</button>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Why Us?</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">This delicious cafe serves as a one-stop shop to satiate your hunger cravings. Whether they are looking for a filling thali or drinks to quench their summary thirst, La Bawarchi has something for everyone. With a plethora of options available, from veg thali and wraps to burgers and pizzas, it is an all-encompassing solution with a vast range of delicacies to choose from!<br/><br/>Finding the best dinner places in Mohali? La Bawarchi brings freshly made chapatis with flavourful curries. Don’t miss out on the lababdar tikkas and tandoori bites to make your snacking nights fun-filled! We Serve What You Need At The Best Times When Your Soul Needs To Get Cheered Up & Boost Your Day Highlights!</p>
        <button type="button" className="custom__button">Know More</button>
      </div>
    </div>
  </div>
);

export default AboutUs;
