import images from './images';

const wines = [
  {
    title: 'Delhi 6 Snacks Vali Gali',
    price: '',
    tags: 'IN | ',
  },
  {
    title: 'The Honey Chilli Heist',
    price: '',
    tags: 'IN | ',
  },
  {
    title: 'Tandoori Nights',
    price: '',
    tags: 'IN | ',
  },
  {
    title: 'Dawat-e-Veg',
    price: '',
    tags: 'IN | ',
  },
  {
    title: 'The Pizza Ride',
    price: '',
    tags: 'IN | ',
  },
];

const cocktails = [
  {
    title: 'Fresh Smoothies',
    price: '',
    tags: 'Mixed Fruit | Flavoured',
  },
  {
    title: "Banta Lemonade",
    price: '',
    tags: 'Fizzy | Salted',
  },
  {
    title: 'Cold Coffee',
    price: '',
    tags: 'Flavoured | Classic |',
  },
  {
    title: 'Shaky Blends',
    price: '',
    tags: 'Fruit | Chocolate |',
  },
  {
    title: 'Mojitos',
    price: '',
    tags: 'Fruit | Virgin |',
  },
];

const awards = [
  {
    imgUrl: images.award01,
    title: 'Fun-Filled Cafe',
    subtitle: 'I was searching for the best restaurants in Mohali for family dinner and my friend suggested La Bawarchi. It was a fun-filling experience with a quick game of snooker.',
  },
  {
    imgUrl: images.award01,
    title: 'Good Ambience',
    subtitle: 'I liked their shakes and tried their cold coffee too. They have a pretty good ambience with a Snooker gaming area.',
  },
  {
    imgUrl: images.award01,
    title: 'Delicious Snacks',
    subtitle: 'I ordered a Paneer Lava burger and fries with my friends and it tasted good. We had a fun time at La Bawarchi - the best cafe in Mohali.',
  },
  {
    imgUrl: images.award01,
    title: 'Best Lunch Place',
    subtitle: 'I was searching for the best lunch places in Mohali near my office area and my friend took me to La Bawarchi, we had a great time there!',
  },
];

export default { wines, cocktails, awards };
