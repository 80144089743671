import React from 'react';

import { SubHeading } from '../../components';
import {images } from '../../constants';
const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="contact" />
      <h1 className="headtext__cormorant" style = {{marginBottom: '3rem'}}>Find Us</h1>
      <div className="app__wrapper-content">
        <p className="p__opensans" style={{ color: '#000' }} >D 178, Ground Floor, La Dafter, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 140308</p>
        <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0'}}>Opening Hours</p>
        <p className="p__opensans" style={{ color: '#000' }} >Mon - Fri: 10:00 AM - 09:00 PM</p>
        <p className="p__opensans" style={{ color: '#000' }} >Sat - Sun: 11:00 AM - 10:00 PM</p>
      </div>
      <a href="https://www.google.com/maps/place/La+Bawarchi/@30.7094033,76.6857166,15z/data=!4m6!3m5!1s0x390fefe5a4055e17:0x8afd619fcd2a77e3!8m2!3d30.7094033!4d76.6857166!16s%2Fg%2F11tsv0z7xl" target="_blank"><button className="custom__button" style={{ marginTop: '2rem'}}>Visit Us</button></a>
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="findus" />  
    </div>    
  </div>
);

export default FindUs;
