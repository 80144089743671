import React from 'react';
import './Popup.css';
import { MdOutlineRestaurantMenu} from 'react-icons/md';

function Popup(props) {
  return (props.trigger) ? (
    <div className="popup">
      <div className="popup-inner">
        <button className="cls-btn" onClick={() => props.setTrigger(false)}>X</button>
        { props.children }
      </div>
    </div>
  ) : "";
}

export default Popup
