import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';
import { AiFillHeart } from 'react-icons/ai';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext" style={{ color: '#000' }} >Contact Us</h1>
        <p className="p__opensans" style={{ color: '#000' }} >D 178, Ground Floor, La Dafter, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 140308</p>
        <p className="p__opensans" style={{ color: '#000' }} >(+91) 9875-929303</p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.gericht} alt="footer_logo" />
        <p className="p__opensans" style={{ color: '#000' }} >&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <a href="https://www.facebook.com/Labawarchi/" target="_blank"><FiFacebook /></a>
          <a href="https://www.instagram.com/la_bawarchi/" target="_blank"><FiInstagram /></a>  
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext" style={{ color: '#000' }} >Working Hours</h1>
        <p className="p__opensans" style={{ color: '#000' }} >Monday-Friday:</p>
        <p className="p__opensans" style={{ color: '#000' }} >10:00 AM - 09:00 PM</p>
        <p className="p__opensans" style={{ color: '#000' }} >Saturday-Sunday:</p>
        <p className="p__opensans" style={{ color: '#000' }} >11:00 AM - 10:00 PM</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans" style={{ color: '#000' }} >© 2023 La Bawarchi | All Rights Reserved. | Designed By anujjgautam</p>
    </div>
  </div>
);

export default Footer;