import React from 'react';

import { images } from '../../constants';
import { SubHeading } from '../../components';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef" />
    </div>

    <div className="app__wrapper_info">
      <SubHeading title="Founder's Word"/>
      <h1 className="headtext__cormorant">What we believe in</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote" />
          <p className="p__opensans">Having variety of dishes on your palette & vast range of drinks is what you need!</p>
        </div>                    
        <p className="p__opensans">We want to be the place where people come to relax, socialize, and enjoy delicious food and drinks. From Breakfast to dinner late at night, we are always ready to serve our foodies in every way, anytime they want! Our Bawarchis cook delicious meals that fill your belly with exciting ideas for your next crucial tasks!</p>
      </div>                     {/**/}   

      <div className="app__chef-sign">
        <p>Harsimran Singh</p>
        <p className="p__opensans">CEO & Founder</p>
        {/*<img src={images.sign} alt="sign" />*/}
      </div>

    </div>
  </div>
);

export default Chef;
